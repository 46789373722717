<template>
    <div style="width: 100%">
        <div class="container">
            <page-header-image class="marginbottom"/>
            <!-- <div style="margin-top: 100px"></div> -->
            <success-story />
            <!-- <div style="margin-top: 150px"></div> -->
        </div>
    </div>
</template>
<script>
import PageHeaderImage from '../components/PageHeaderImage.vue';
import SuccessStory from './Home/successStory.vue';

export default {
  components: {
    PageHeaderImage,
    SuccessStory,
  },
};
</script>
<style scoped>
    *{
        padding: 0;
        box-sizing: border-box;
        margin: 0;
    }
    .container{
        width: inherit;
    }

    @media screen and (min-width: 800px){
        .marginbottom{margin-bottom: 100px;}
    }
</style>
